/**
 * App Component
 *
 *   Top-level routing for logged-in and logged-out states.
 *
 */
import Dashboard from "./Dashboard";
import Login from "./Login";
import { Router } from "@reach/router";
import React from "react";

import { ToastProvider } from "react-toast-notifications";
import { ProvideAuth } from "../hooks/useAuth";

// @todo: Make this work
import { ConnectivityListenerService } from "../services/ConnectivityListenerService";

//Returns a promise.

export default function App() {
  return (
    <ProvideAuth>
      <ToastProvider autoDismiss autoDismissTimeout={6000}>
        <React.StrictMode>
          <ConnectivityListenerService />
          <Router>
            <Dashboard path="/*" />
            <Login path="login" />
          </Router>
        </React.StrictMode>
      </ToastProvider>
    </ProvideAuth>
  );
}
