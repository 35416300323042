/**
 * Dashboard Component
 *
 *   Top-level routing for the logged-in state
 *
 */

// Base
import { useNavigate } from "@reach/router";
import { Router } from "@reach/router";

// Hooks
import { useAuth } from "../hooks/useAuth";

// Components
import Loader from "./Loader";
import Nav from "./Nav";
import Pages from "./Pages/Pages";
import Page from "./Page/Page";
import Videos from "./Videos/Videos";
import Video from "./Video/Video";
import PageViews from "./PageViews/PageViews";

export default function Dashboard() {
  // Navigate to Login page if logged out
  const navigate = useNavigate();
  const auth = useAuth();

  if (auth.loggedIn === null) return <Loader />;
  else if (auth.loggedIn === false) navigate("/login");

  return (
    <div className="min-h-screen bg-gray-100">
      <Nav />
      <main className="px-5 pb-5 mx-auto mt-5 max-w-7xl">
        <Router>
          <Pages path="/" />
          <Pages path="pages" />
          <Page isNew={true} path="pages/create" />
          <Page isNew={false} path="pages/:url_password" />

          <Videos path="/videos" />
          <Video isNew={false} path="videos/:id" />
          <Video isNew={true} path="videos/create" />

          <PageViews path="analytics/:id" />
        </Router>
      </main>
    </div>
  );
}
