/**
 * Service
 *
 *   Base class implementing inheritable methods for use across all services
 *
 */
import "whatwg-fetch"; // Polyfill for older browsers
import "abortcontroller-polyfill/dist/abortcontroller-polyfill-only";

export default class Service {
  /**
   * Performs a fetch call to the API
   *
   * @param {String} endpoint The endpoint on the API that you want to call
   * @param {String} method GET, POST, PATCH, or DELETE
   * @param {Object} body Series of key/value pairs you want to pass in the request body
   */
  static async apiCall(endpoint, method, body) {
    let options = {
      method: method,
      credentials: "include",
      headers: { Accept: "application/json", "Content-Type": "application/json" },
    };
    if (body) options.body = JSON.stringify(body);

    return await this.fetchWithTimeout(`${process.env.REACT_APP_API_URL}${endpoint}`, options)
      .then(async (res) => {
        if (res.status === 200) return await res.json();
        return false;
      })
      .catch(() => false);
  }

  static async fetchWithTimeout(uri, options = {}, time = process.env.REACT_APP_FETCH_TIMEOUT_MS) {
    // Lets set up our `AbortController`, and create a request options object
    // that includes the controller's `signal` to pass to `fetch`.
    const controller = new AbortController();
    const config = { ...options, signal: controller.signal };

    // Set a timeout limit for the request using `setTimeout`. If the body
    // of this timeout is reached before the request is completed, it will
    // be cancelled.
    setTimeout(() => {
      controller.abort();
    }, time);

    return fetch(uri, config)
      .then((response) => {
        // Because _any_ response is considered a success to `fetch`, we
        // need to manually check that the response is in the 200 range.
        // This is typically how I handle that.
        if (!response.ok) {
          throw new Error(`${response.status}: ${response.statusText}`);
        }

        return response;
      })
      .catch((error) => {
        // When we abort our `fetch`, the controller conveniently throws
        // a named error, allowing us to handle them separately from
        // other errors.
        if (error.name === "AbortError") {
          throw new Error("Response timed out");
        }

        throw new Error(error.message);
      });
  }
}
