/**
 * PagesTableRow
 *
 *
 */

// Base
import { Fragment } from "react";
import { Link } from "@reach/router";
import { DateTime } from "luxon";
import { useToasts } from "react-toast-notifications";

// API
import PageService from "../../services/PageService";
import { ConsumeAuth } from "../../hooks/useAuth";

export default function PagesTableRow({ rowData, resetPages }) {
  const toasts = useToasts();
  let createdAtObject = DateTime.fromISO(rowData.created_at);

  function copyUrlPassword(publicUrl, password) {
    navigator.clipboard.writeText(`${publicUrl}/${password}`);
    let content = (
      <Fragment>
        <strong>Password Copied</strong>
        <div>{`${publicUrl}/${password}`}</div>
      </Fragment>
    );
    toasts.addToast(content, { appearance: "info" });
  }

  function openPageInNewWindow(publicUrl, password) {
    window.open(`${publicUrl}/${password}`);
  }

  /**
   * Does not ask for permission before deleting!
   *
   * @param {Event} e
   */
  function deletePage(e) {
    PageService.delete(e.currentTarget.dataset.pageid)
      .then((success) => {
        if (success) {
          resetPages(true); // Reset page list
          return toasts.addToast("Page Deleted.", { appearance: "success" });
        }
        toasts.addToast(`Error deleting page.`, { appearance: "error" });
      })
      .catch((err) => toasts.addToast(`Error deleting page: ${err}`, { appearance: "error" }));
  }

  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap">
        <Link to={`pages/${rowData.url_password}`}>
          <div className="flex items-center">
            <div className="w-40 overflow-hidden sm:w-60 lg:w-auto">
              <div className="overflow-hidden text-sm font-medium text-gray-900 overflow-ellipsis">{rowData.name}</div>
              <div className="w-full overflow-hidden text-sm text-gray-500 overflow-ellipsis">{rowData.slug}</div>
            </div>
          </div>
        </Link>
      </td>
      <td className="hidden px-6 py-4 md:table-cell whitespace-nowrap">
        <div className="flex items-center">
          <div className="">
            <div className="text-sm font-medium text-gray-700">{rowData.viewer_name}</div>
            <div className="max-w-sm overflow-hidden text-sm text-gray-500 overflow-ellipsis">
              {rowData.viewer_company}
            </div>
          </div>
        </div>
      </td>
      <td className="hidden px-6 py-4 lg:table-cell whitespace-nowrap">
        <div className="flex items-center">
          <div className="">
            <div className="text-sm font-medium text-gray-700">{createdAtObject.toRelative()}</div>
            <div className="text-sm text-gray-500">{createdAtObject.toLocaleString(DateTime.DATETIME_MED)}</div>
          </div>
        </div>
      </td>
      <td className="py-4 w-60 whitespace-nowrap">
        <div className="flex flex-row justify-evenly">
          {ConsumeAuth(({ userData: { public_url } }) => (
            <Fragment>
              <button onClick={() => openPageInNewWindow(public_url, rowData.url_password)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 font-semibold text-indigo-600 hover:text-indigo-900"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                  />
                </svg>
              </button>
              <button onClick={() => copyUrlPassword(public_url, rowData.url_password)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 font-semibold text-indigo-600 hover:text-indigo-900"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
                  />
                </svg>
              </button>
            </Fragment>
          ))}
          <Link to={`pages/${rowData.url_password}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 text-indigo-600 hover:text-indigo-900"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
              />
            </svg>
          </Link>
          <Link to={`analytics/${rowData.id}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 text-indigo-600 hover:text-indigo-900"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
              />
            </svg>
          </Link>
          <button data-pageid={rowData.id} onClick={deletePage}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 text-red-500 hover:text-red-900"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
          </button>
        </div>
      </td>
    </tr>
  );
}
