/**
 * Page Component
 *
 *   Displays a single page - either adding a new page (blank) OR updating an existing one.
 *
 */

// Base
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "@reach/router";
import { useToasts } from "react-toast-notifications";

// API
import PageService from "../../services/PageService";

// Components
import VideoPreviewList from "./VideoPreviewList";
import Loader from "../Loader";

export default function Page({ isNew }) {
  // Setup
  const toast = useToasts();
  const params = useParams();
  const navigate = useNavigate();

  // Form State
  const [page, setPage] = useState(params.page);
  const [id, setId] = useState(null);
  const [title, setTitle] = useState("");
  const [intro, setIntro] = useState("");
  const [outro, setOutro] = useState("");
  const [name, setName] = useState("");
  const [urlPassword, setUrlPassword] = useState("");
  const [slug, setSlug] = useState("");
  const [viewerName, setViewerName] = useState("");
  const [viewerCompany, setViewerCompany] = useState("");
  const [videos, setVideos] = useState("");

  // Effect loads initial form state if we're not making a new page.
  useEffect(() => {
    // Don't attempt to fetch data if we're creating a new page
    if (isNew === undefined || !isNew) {
      console.log("fetching page data...");
      PageService.get(params.url_password).then((res) => {
        if (!res) return navigate("/");
        setPage(res);
        setId(res.id);
        setTitle(res.title || ""); // for nullable properties, use empty string to make react happy
        setIntro(res.intro || "");
        setOutro(res.outro || "");
        setName(res.name || "");
        setUrlPassword(res.url_password);
        setSlug(res.slug || "");
        setViewerName(res.viewer_name || "");
        setViewerCompany(res.viewer_company || "");
        setVideos(res.videos);
      }); // Should be the same as (pageInfo) => setPage(pageInfo)
    }
  }, [params.url_password, navigate, isNew]);

  /**
   * Updates the page.
   *
   * @param {Event} e
   */
  function updatePage(e) {
    e.preventDefault();
    PageService.update(id, getData()).then((res) => {
      if (res) {
        toast.addToast("Update successful!", { appearance: "success" });
        return navigate("/");
      } else toast.addToast("Error updating.", { appearance: "error" });
      // Move to the new url password page if it changes
      if (urlPassword !== params.url_password) return navigate(urlPassword);
    });
  }

  /**
   * Creates a new page then redirects to home.
   *
   * @param {Event} e
   */
  function newPage(e) {
    e.preventDefault();
    if (!urlPassword)
      return toast.addToast("You must specify a url/password to create a page.", { appearance: "error" });
    PageService.create(getData()).then((res) => {
      if (res) toast.addToast("Page created!", { appearance: "success" });
      else toast.addToast("Error creating page.", { appearance: "error" });
      // Exit
      return navigate("/");
    });
  }

  /**
   * Helper function gets data from the form.
   *
   * @returns {Object} form data
   */
  function getData() {
    return {
      title: title,
      intro: intro,
      outro: outro,
      name: name,
      url_password: urlPassword,
      slug: slug,
      viewer_name: viewerName,
      viewer_company: viewerCompany,
    };
  }

  if (!isNew && !urlPassword) return <Loader />;
  return (
    <div>
      <h1 className="mt-4 text-xl font-bold">{isNew ? "Create Page" : "Edit Page"}</h1>
      <div className="flex flex-wrap min-w-full mt-5">
        <div className="flex-grow w-full mb-4 mr-4 lg:w-5/12">
          <div className="w-full rounded-md shadow-md sm:overflow-hidden">
            <div className="px-4 py-5 space-y-6 bg-white sm:p-6">
              <div className="text-lg">Public Options</div>

              <div>
                <label htmlFor="page_name" className="block text-sm font-medium text-gray-700">
                  Title
                </label>
                <input
                  type="text"
                  name="page_title"
                  id="page_title"
                  className="flex-1 block w-full p-2 mt-1 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Add an opener to be displayed on bold."
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                  Intro Text
                </label>
                <textarea
                  id="intro"
                  name="intro"
                  rows="3"
                  className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="This intro text appears between the title and the work cards."
                  value={intro}
                  onChange={(e) => setIntro(e.target.value)}
                ></textarea>
              </div>

              <div>
                <span className="block mb-2 text-sm font-medium text-gray-700">Videos</span>
                {isNew ? "Create page before adding videos." : <VideoPreviewList pageId={id} />}
              </div>
              <div>
                <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                  Outro Text
                </label>
                <textarea
                  id="outro"
                  name="outro"
                  rows="3"
                  className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="This intro text appears between the title and the work cards."
                  value={outro}
                  onChange={(e) => setOutro(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-grow w-full mb-4 mr-4 lg:w-5/12">
          <div className="w-full rounded-md shadow-md sm:overflow-hidden">
            <div className="px-4 py-5 space-y-6 bg-white sm:p-6">
              <div className="text-lg">Admin Options</div>
              <div>
                <label htmlFor="page_name" className="block text-sm font-medium text-gray-700">
                  Page Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="flex-1 block w-full p-2 mt-1 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Add a name to identify the page in the admin panel."
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="page_name" className="block text-sm font-medium text-gray-700">
                  URL / Password
                </label>
                <input
                  type="text"
                  name="url_password"
                  id="url_password"
                  className="flex-1 block w-full p-2 mt-1 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="eg. https://danielberesh.com/work/thisIsTheUrlPassword"
                  value={urlPassword}
                  onChange={(e) => setUrlPassword(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="page_name" className="block text-sm font-medium text-gray-700">
                  Page Description
                </label>
                <textarea
                  id="slug"
                  name="slug"
                  rows="3"
                  className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Briefly describe why you're making this page."
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                ></textarea>
              </div>
              <div>
                <label htmlFor="page_name" className="block text-sm font-medium text-gray-700">
                  Viewer Name
                </label>
                <input
                  type="text"
                  name="viewer_name"
                  id="viewer_name"
                  className="flex-1 block w-full p-2 mt-1 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Add a name to identify the page in the admin panel."
                  value={viewerName}
                  onChange={(e) => setViewerName(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="page_name" className="block text-sm font-medium text-gray-700">
                  Viewer Company
                </label>
                <input
                  type="text"
                  name="viewer_company"
                  id="viewer_company"
                  className="flex-1 block w-full p-2 mt-1 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Add a name to identify the page in the admin panel."
                  value={viewerCompany}
                  onChange={(e) => setViewerCompany(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 py-3 text-right sm:px-6">
          <button
            id="saveButton"
            onClick={isNew ? newPage : updatePage}
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {isNew ? "Create" : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
}
