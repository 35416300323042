/**
 * PageAnalyticsRow
 *
 *
 */
import { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { UAParser } from "ua-parser-js";
import PageViewService from "../../services/PageViewService";
import { useToasts } from "react-toast-notifications";

export default function PageViewsRow({ rowData }) {
  const [timeSincePageView, setTimeSincePageView] = useState("Loading...");
  const [startTime, setStartTime] = useState("");
  const [geo, setGeo] = useState("Loading...");
  const [ua, setUa] = useState("Loading...");
  const [render, setRender] = useState(true);
  const toasts = useToasts();

  async function deletePageView(pvId) {
    if (await PageViewService.delete(pvId)) {
      setRender(false); // Hide this row until we reload the parent component
      toasts.addToast("Page view deleted.", { appearance: "success" });
    } else {
      toasts.addToast("Error deleting page view.", { appearance: "error" });
    }
  }

  useEffect(() => {
    {
      let dt = DateTime.fromISO(rowData.start_time);
      setTimeSincePageView(dt.toRelative());
      setStartTime(dt.toLocaleString(DateTime.DATETIME_MED));
    }
    {
      let geolocation;
      try {
        geolocation = rowData.geolocation ? JSON.parse(rowData.geolocation) : undefined;
      } catch (err) {
        geolocation = undefined;
      }
      setGeo(geolocation);
    }
    {
      let uaParser = new UAParser(rowData.user_agent);
      setUa(
        `${uaParser.getBrowser().name} / ${uaParser.getDevice().type || ""} ${uaParser.getDevice().vendor || ""} ${
          uaParser.getOS().name
        }`
      );
    }
  }, [setTimeSincePageView, setStartTime, setGeo, setUa, rowData]);

  if (render)
    return (
      <tr>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="">
            <div className="text-sm font-medium text-gray-900">{timeSincePageView}</div>
            <div className="max-w-md overflow-hidden text-sm text-gray-500 overflow-ellipsis">{startTime}</div>
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="flex items-center">
            <div className="">
              {geo ? (
                <div className="text-sm font-medium">
                  {
                    <a
                      className="text-blue-500 underline"
                      href={`https://google.com/maps/search/${geo["lat"]},${geo["lon"]}`}
                    >
                      {rowData.city ? rowData.city : "Trace"}
                    </a>
                  }
                </div>
              ) : (
                "No Data"
              )}
              <div className="text-sm text-gray-500">{rowData.ip}</div>
            </div>
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="flex items-center">
            <div className="">
              <div className="text-sm text-gray-500">{ua}</div>
            </div>
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="flex items-center">
            <div className="">
              <div className="text-sm text-gray-500">{rowData.cookie}</div>
            </div>
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="flex items-center">
            <button onClick={() => deletePageView(rowData.id)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 font-semibold text-indigo-600 hover:text-indigo-900"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </button>
          </div>
        </td>
      </tr>
    );
  else return "";
}
