/**
 * Video Component
 *
 *   Displays details of a single video
 *
 */

// Base
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "@reach/router";
import { useToasts } from "react-toast-notifications";

// API
import VideoService from "../../services/VideoService";

// Components
import Loader from "../Loader";

export default function Video({ isNew }) {
  // Setup
  const toast = useToasts();
  const params = useParams();
  const navigate = useNavigate();

  // Form State
  const [id, setId] = useState(params.id);
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [brandLogo, setBrandLogo] = useState("");
  const [defaultBeforeText, setDefaultBeforeText] = useState("");
  const [defaultAfterText, setDefaultAfterText] = useState("");

  // Effect loads initial form state if we're not making a new page.
  useEffect(() => {
    // Don't attempt to fetch data if we're creating a new page
    if (isNew === undefined || !isNew) {
      VideoService.get(params.id).then((res) => {
        if (!res) return navigate("/videos");
        setTitle(res.title);
        setUrl(res.url);
        setBrandLogo(res.brand_logo || ""); // for nullable properties, use empty string to make react happy
        setDefaultBeforeText(res.default_before_text || "");
        setDefaultAfterText(res.default_after_text);
      });
    }
  }, [params.id, navigate, isNew]);

  /**
   * Updates the video.
   *
   * @param {Event} e
   */
  function updateVideo(e) {
    e.preventDefault();
    VideoService.update(id, getData()).then((res) => {
      if (res) {
        toast.addToast("Update successful!", { appearance: "success" });
        return navigate("/videos");
      }
      toast.addToast("Error updating.", { appearance: "error" });
    });
  }

  /**
   * Creates a new video then redirects to home on success.
   *
   * @param {Event} e
   */
  function newVideo(e) {
    e.preventDefault();
    VideoService.create(getData()).then((res) => {
      if (!res) return toast.addToast("Error creating video.", { appearance: "error" });
      toast.addToast("Video created!", { appearance: "success" });
      return navigate("/videos"); // Only return to main page if created successfully.
    });
  }

  /**
   * Helper function gets data from the form.
   *
   * @returns {Object} form data
   */
  function getData() {
    return {
      title: title,
      url: url,
      brand_logo: brandLogo,
      default_before_text: defaultBeforeText,
      default_after_text: defaultAfterText,
    };
  }

  if (!isNew && !title) return <Loader />;
  return (
    <div className="flex flex-col min-w-full flex-nowrap">
      <h1 className="text-xl font-bold">{isNew ? "Create Video" : "Edit Video"}</h1>
      <div className="flex-grow min-w-full px-4 py-5 my-4 space-y-6 bg-white rounded-md shadow-md lg:w-5/12 sm:overflow-hidden sm:p-6">
        <div>
          <label htmlFor="video_title" className="block text-sm font-medium text-gray-700">
            Title
          </label>
          <input
            type="text"
            name="video_title"
            id="video_title"
            className="flex-1 block w-full p-2 mt-1 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Add an opener to be displayed on bold."
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="default-before-text" className="block text-sm font-medium text-gray-700">
            Default Before Text
          </label>
          <textarea
            id="default-before-text"
            name="default-before-text"
            rows="3"
            className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="This intro text appears between the title and the work cards."
            value={defaultBeforeText}
            onChange={(e) => setDefaultBeforeText(e.target.value)}
          ></textarea>
        </div>
        <div>
          <label htmlFor="url" className="block text-sm font-medium text-gray-700">
            Vimeo Url
          </label>
          <input
            type="text"
            name="url"
            id="url"
            className="flex-1 block w-full p-2 mt-1 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="https://vimeo.com/43543098234"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="default-after-text" className="block text-sm font-medium text-gray-700">
            Default After Text
          </label>
          <textarea
            id="default-after-text"
            name="default-after-text"
            rows="3"
            className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="This intro text appears between the title and the work cards."
            value={defaultAfterText}
            onChange={(e) => setDefaultAfterText(e.target.value)}
          ></textarea>
        </div>
        <div>
          <label htmlFor="brand_logo" className="block text-sm font-medium text-gray-700">
            Brand Logo
          </label>
          <input
            type="text"
            name="brand_logo"
            id="brand_logo"
            className="flex-1 block w-full p-2 mt-1 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="URL of the brand logo to be displayed."
            value={brandLogo}
            onChange={(e) => setBrandLogo(e.target.value)}
          />
        </div>
      </div>
      <div className="py-3">
        <button
          id="saveButton"
          onClick={isNew ? newVideo : updateVideo}
          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {isNew ? "Create" : "Save"}
        </button>
      </div>
    </div>
  );
}
