/**
 * Videos Component
 *
 *   Displays a list of videos.
 *
 */

// Base
import React, { useState, useEffect } from "react";
import { Link } from "@reach/router";

// API
import VideoService from "../../services/VideoService";

// Components
import VideosTableRow from "./VideosTableRow";
import Loader from "../Loader";

export default function Videos() {
  const [videos, setVideos] = useState(false);
  const [resetTrigger, resetVideos] = useState(false);

  useEffect(() => {
    VideoService.list().then(setVideos); // equivalent to (updatedPages) => setPages(updatedPages)
    resetVideos(false);
  }, [setVideos, resetTrigger]);

  if (!videos) return <Loader />;
  else
    return (
      <div className="flex flex-col">
        <Link to="/videos/create">
          <div className="flex items-center border border-dashed border-blue-500 rounded-md text-blue-500 cursor-pointer hover:bg-blue-100 px-4 py-4 mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 mr-2 inline-block"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span className="uppercase text-sm">Add Video</span>
          </div>
        </Link>
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="table-auto min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Vimeo
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                      <span className="sr-only">Link</span>
                      <span className="sr-only">Delete</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {videos.map((rowData) => (
                    <VideosTableRow key={rowData.id} rowData={rowData} resetVideos={resetVideos} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
}
