/**
 * Add Video Modal Component
 *
 *   Popup that allows users to select a video to add to their reel.
 *
 */

// Base
import { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";

// API
import VideoService from "../../services/VideoService";
import PageService from "../../services/PageService";

export default function AddVideoModal({ pageId, modalVisible, setModalVisible, existingVideos }) {
  const toast = useToasts();
  const [videos, setVideos] = useState(existingVideos);

  useEffect(() => {
    VideoService.list().then(setVideos);
  }, [setVideos, modalVisible]);

  /**
   * Adds a video to the page.
   *
   * @param {Event} e
   */
  function addVideo(e) {
    // ev.currentTarget.dataset.videoId will contain the video id for the button that was clicked
    PageService.addVideo(pageId, e.currentTarget.dataset.videoid).then((res) => {
      if (res) {
        // Show a toast notification
        toast.addToast("Video Added", { appearance: "success" });
        setModalVisible(false);
      } else {
        toast.addToast("Error adding video", { appearance: "error" });
      }
    });
  }

  if (!modalVisible) return null;

  return (
    <div id="modal-wrapper" className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div
            className="absolute inset-0 bg-gray-500 opacity-75"
            onClick={() => {
              setModalVisible(false);
            }}
          ></div>
        </div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex flex-col">
            <div className="top-title flex flex-column items-center mb-4">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  className="w-6 h-6 text-blue-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z"
                  />
                </svg>
              </div>
              <h3 className="ml-4 text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                Select a video to add
              </h3>
            </div>

            <div className="mt-3 text-center sm:mt-0 sm:text-left">
              <div className="mt-2">
                {/* Check if the video has already been added to the page. If so, don't list it */}
                {videos.map((video) =>
                  existingVideos.includes(video.id) > 0 ? (
                    ""
                  ) : (
                    <button
                      key={video.title.replace(/ /g, "").toLowerCase()}
                      data-videoid={video.id}
                      onClick={addVideo}
                      className="min-w-full border border-blue-100 px-4 py-2 rounded-md my-2 hover:bg-gray-100 flex items-center justify-left"
                    >
                      <div className="mr-3 flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-full bg-blue-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 mx-2 text-blue-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                          />
                        </svg>
                      </div>
                      <div className="inline-block capitalize text-left">{video.title}</div>
                    </button>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="px-4 pb-6 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={() => {
                setModalVisible(false);
              }}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
