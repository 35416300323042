/**
 * Pages.js
 *
 *   Displays a list of pages.
 *
 */

// Base
import React, { useState, useEffect, Fragment } from "react";
import { useToasts } from "react-toast-notifications";
import { Link } from "@reach/router";

// API
import PageService from "../../services/PageService";

// Components
import PagesTableRow from "./PagesTableRow";
import Loader from "../Loader";

export default function Pages() {
  // Setup
  const { addToast } = useToasts();

  // State
  const [pages, setPages] = useState(false);
  const [resetTrigger, resetPages] = useState(false);

  //Load pages data for display
  useEffect(() => {
    PageService.list().then(setPages); // equivalent to (updatedPages) => setPages(updatedPages)
    resetPages(false); // mark trigger to false
  }, [setPages, resetTrigger]);

  if (!pages) return <Loader />;
  else
    return (
      <div className="flex flex-col">
        <Link to="/pages/create">
          <div className="flex items-center border border-dashed border-blue-500 rounded-md text-blue-500 cursor-pointer hover:bg-blue-100 px-4 py-4 mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 mr-2 inline-block"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span className="uppercase text-sm">Add Page</span>
          </div>
        </Link>
        <div className="my-4 min-w-full shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="table-auto min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Page
                </th>
                <th
                  scope="col"
                  className="hidden md:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Viewer
                </th>
                <th
                  scope="col"
                  className="hidden lg:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Created
                </th>
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {pages.map((rowData) => (
                <PagesTableRow key={rowData.id} rowData={rowData} resetPages={resetPages} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
}
