/**
 * Nav Component
 *
 *   Provides top-bar navigation for logged-in state
 *
 */
import { Link } from "@reach/router";
import { ConsumeAuth } from "../hooks/useAuth";

export default function Nav() {
  return (
    <nav className="bg-gray-800">
      <div className="px-2 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="block w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              <svg
                className="hidden w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="flex items-center justify-center flex-1 sm:items-stretch sm:justify-start">
            <Link to="/">
              <div className="flex items-center flex-shrink-0">
                <span className="text-2xl font-semibold text-white">Parfocal</span>
              </div>
            </Link>
            <div className="hidden sm:block sm:ml-6">
              <div className="flex space-x-4">
                <Link to="/">
                  <div className="px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-md">Pages</div>
                </Link>
                <Link to="videos">
                  <div className="px-3 py-2 text-sm font-medium text-gray-300 rounded-md hover:bg-gray-700 hover:text-white">
                    Videos
                  </div>
                </Link>
                {/* <Link to="analytics">
                  <div className="px-3 py-2 text-sm font-medium text-gray-300 rounded-md hover:bg-gray-700 hover:text-white">
                    Analytics
                  </div>
                </Link> */}
              </div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 text-white sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            {ConsumeAuth(({ userData }) => `Logged in as ${userData.email}`)}

            {/* <Link to="createPage">
              <div className="px-3 py-2 text-sm font-medium text-gray-300 rounded-md hover:bg-gray-700 hover:text-white">
                New Page
              </div>
            </Link> */}
          </div>
        </div>
      </div>
      <div className="hidden sm:hidden">
        <div className="px-2 pt-2 pb-3 space-y-1">
          <Link to="/">
            <div className="px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-md">Pages</div>
          </Link>
          <Link to="videos">
            <div className="px-3 py-2 text-sm font-medium text-gray-300 rounded-md hover:bg-gray-700 hover:text-white">
              Videos
            </div>
          </Link>
          <Link to="analytics">
            <div className="px-3 py-2 text-sm font-medium text-gray-300 rounded-md hover:bg-gray-700 hover:text-white">
              Analytics
            </div>
          </Link>
        </div>
      </div>
    </nav>
  );
}
