/**
 * PageService
 *
 *  "Air traffic control" methods for communicating with Pages in the API.
 *  Uses apiCall method inherited from Service class to fetch.
 *
 */
import Service from "./Service";

export default class PageViewService extends Service {
  // No create method needed for admin backend

  /**
   * List all page views for a given page — not paginated
   *
   */
  static async list(pageId) {
    return (await this.apiCall(`/pageViews/allFor/${pageId}`, "GET")).data;
  }

  /**
   * Delete a single page view
   *
   * @param {Number} id
   */
  static async delete(id) {
    return await this.apiCall(`/pageViews/${id}`, "DELETE");
  }
}
