/**
 * AuthService
 *
 *  "Air traffic control" methods for communicating with Users in the API.
 *  Uses apiCall method inherited from Service class to fetch.
 *
 */
import Service from "./Service";

export default class AuthService extends Service {
  /**
   * Checks if a user is logged in by validating the token cookie.
   * Note that we can't access the cookie from the front-end, so we'll just send a check to /users/checktoken
   * and if it comes back true, we're logged in. We have to trust that the browser will stick the
   * cookie into the auth params of the fetch request.
   *
   */
  static async isLoggedIn() {
    return await this.apiCall("/users/checktoken", "GET");
  }

  /**
   * Attempts to log a user in with a given email and password.
   *
   * @param {String} email
   * @param {String} password
   * @returns user details on login success; false on failure
   */
  static async login(email, password) {
    return await this.apiCall("/users/login", "POST", { user: { email: email, password: password } });
  }

  /**
   * Attempts to log a user in with a given email and password.
   *
   * @param {String} email
   * @param {String} password
   * @returns true on login success; false on failure
   */
  static async register(email, password) {
    return !!(await this.apiCall("/users/register", "POST", { user: { email: email, password: password } }));
  }
}
