/**
 * PageService
 *
 *  "Air traffic control" methods for communicating with Pages in the API.
 *  Uses apiCall method inherited from Service class to fetch.
 *
 */
import Service from "./Service";

export default class PageService extends Service {
  /**
   * Create a new page
   * @param {Object} data
   */
  static async create(data) {
    return await this.apiCall(`/pages`, "POST", { page: data });
  }
  /**
   * List all pages — not paginated
   *
   */
  static async list() {
    return (await this.apiCall("/pages", "GET")).data;
  }

  /**
   * List all videos for a given page — not paginated
   *
   */
  static async listVideos(pageId) {
    return (await this.apiCall(`/pages/${pageId}/listVideos`, "GET")).data;
  }

  /**
   * Get a single page
   *
   * @param {Number} id Page ID
   */
  static async get(id) {
    return (await this.apiCall(`/pages/${id}`, "GET")).data;
  }

  /**
   * Update a single page
   *
   * @param {Number} id
   * @param {Object} data
   */
  static async update(id, data) {
    return await this.apiCall(`/pages/${id}`, "PATCH", { page: data });
  }

  /**
   * Sets a new order for the videos associated with a given page from the array orderedVideoIds
   * which should look like [ videoId, videoId, videoId ] (eg. [43, 24, 54])
   *
   * @param {Number} id
   * @param {Array} orderedVideoIds
   */
  static async setVideoOrder(id, orderedVideoIds) {
    return await this.apiCall(`/pages/${id}/setVideoOrder`, "PATCH", { orderedVideoIds: orderedVideoIds });
  }

  /**
   * Add a single video to a single page
   *
   * @param {Number} pageId
   * @param {Number} videoId
   */
  static async addVideo(pageId, videoId) {
    return await this.apiCall(`/pages/${pageId}/addVideo/${videoId}`, "POST");
  }

  /**
   * Remove (unrelate) a single video from a single page
   *
   * @param {Number} pageId
   * @param {Number} videoId
   */
  static async removeVideo(pageId, videoId) {
    return await this.apiCall(`/pages/${pageId}/removeVideo/${videoId}`, "POST");
  }

  /**
   * Delete a single page
   *
   * @param {Number} id
   */
  static async delete(id) {
    return await this.apiCall(`/pages/${id}`, "DELETE");
  }
}
