/**
 * Loader
 *
 *   Provides a spinner for loading states
 *
 */

export default function Loader() {
  return (
    <div className="absolute top-2/4 left-2/4 -ml-5 -mt-5">
      <svg xmlns="http://www.w3.org/2000/svg" version="1.0" className="w-10 h-10" viewBox="0 0 128 128">
        <g>
          <path d="M76.34 52.05l-43.6-43.6a63.42 63.42 0 0 1 29.7-8.2z" fill="#000000" />
          <path d="M76.34 52.05l-43.6-43.6a63.42 63.42 0 0 1 29.7-8.2z" fill="#c4c4c4" transform="rotate(30 64 64)" />
          <path d="M76.34 52.05l-43.6-43.6a63.42 63.42 0 0 1 29.7-8.2z" fill="#b2b2b2" transform="rotate(60 64 64)" />
          <path d="M76.34 52.05l-43.6-43.6a63.42 63.42 0 0 1 29.7-8.2z" fill="#a1a1a1" transform="rotate(90 64 64)" />
          <path d="M76.34 52.05l-43.6-43.6a63.42 63.42 0 0 1 29.7-8.2z" fill="#8f8f8f" transform="rotate(120 64 64)" />
          <path d="M76.34 52.05l-43.6-43.6a63.42 63.42 0 0 1 29.7-8.2z" fill="#7d7d7d" transform="rotate(150 64 64)" />
          <path d="M76.34 52.05l-43.6-43.6a63.42 63.42 0 0 1 29.7-8.2z" fill="#6b6b6b" transform="rotate(180 64 64)" />
          <path d="M76.34 52.05l-43.6-43.6a63.42 63.42 0 0 1 29.7-8.2z" fill="#595959" transform="rotate(210 64 64)" />
          <path d="M76.34 52.05l-43.6-43.6a63.42 63.42 0 0 1 29.7-8.2z" fill="#474747" transform="rotate(240 64 64)" />
          <path d="M76.34 52.05l-43.6-43.6a63.42 63.42 0 0 1 29.7-8.2z" fill="#363636" transform="rotate(270 64 64)" />
          <path d="M76.34 52.05l-43.6-43.6a63.42 63.42 0 0 1 29.7-8.2z" fill="#242424" transform="rotate(300 64 64)" />
          <path d="M76.34 52.05l-43.6-43.6a63.42 63.42 0 0 1 29.7-8.2z" fill="#121212" transform="rotate(330 64 64)" />
          <animateTransform
            attributeName="transform"
            type="rotate"
            values="0 64 64;30 64 64;60 64 64;90 64 64;120 64 64;150 64 64;180 64 64;210 64 64;240 64 64;270 64 64;300 64 64;330 64 64"
            calcMode="discrete"
            dur="1320ms"
            repeatCount="indefinite"
          ></animateTransform>
        </g>
      </svg>
    </div>
  );
}
