/**
 * Video Service
 *
 *  "Air traffic control" methods for communicating with Videos in the API.
 *  Uses apiCall method inherited from Service class to fetch.
 *
 */
import Service from "./Service";

export default class VideoService extends Service {
  /**
   * Creates a new video
   *
   * @param {Object} data
   */
  static async create(data) {
    console.log("creating with ", data);
    return await this.apiCall(`/videos`, "POST", { video: data });
  }

  /**
   * List all videos. Not paginated.
   *
   */
  static async list() {
    return (await this.apiCall("/videos", "GET")).data;
  }

  /**
   * Get a single video
   *
   * @param {Number} id Page ID
   */
  static async get(id) {
    return (await this.apiCall(`/videos/${id}`, "GET")).data;
  }

  /**
   * Update a single video
   *
   * @param {Number} id
   * @param {Object} data
   */
  static async update(id, data) {
    return await this.apiCall(`/videos/${id}`, "PATCH", { video: data });
  }

  /**
   * Delete a single video
   *
   * @param {Number} id
   */
  static async delete(id) {
    return await this.apiCall(`/videos/${id}`, "DELETE");
  }
}
