/**
 * PagesTableRow
 *
 *
 */
import { Link } from "@reach/router";
import { useToasts } from "react-toast-notifications";
import { Fragment } from "react";

// API
import VideoService from "../../services/VideoService";

export default function VideosTableRow({ rowData, resetVideos }) {
  const toasts = useToasts();

  function deleteVideo(e) {
    VideoService.delete(e.currentTarget.dataset.videoid)
      .then((success) => {
        if (success) {
          resetVideos(true); // Reset video list
          return toasts.addToast("Video Deleted.", { appearance: "success" });
        }
        toasts.addToast(
          <Fragment>
            <div className="font-bold">Error deleting video.</div>
            <div>Please note you can't delete a video if it appears on any of your pages.</div>
          </Fragment>,
          { appearance: "error" }
        );
      })
      .catch((err) => toasts.addToast(`Error deleting video: ${err}`, { appearance: "error" }));
  }

  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap">
        <Link to={`${rowData.id}`}>
          <div className="flex items-center">
            <div className="">
              <div className="text-sm font-medium text-gray-700">{rowData.title}</div>
            </div>
          </div>
        </Link>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="">
            <div className="text-sm text-gray-500">
              <a
                className="hover:text-blue-500 hover:underline"
                title="Visit this video's URL"
                href={rowData.url}
                target="_blank"
                rel="noreferrer"
              >
                {rowData.url}
              </a>
            </div>
          </div>
        </div>
      </td>
      <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
        <Link to={`${rowData.id}`}>
          <span className="text-indigo-600 hover:text-indigo-900">Edit</span>
        </Link>
        <button data-videoid={rowData.id} onClick={deleteVideo} className="pl-4">
          <span className="text-red-500 hover:text-red-900">Delete</span>
        </button>
      </td>
    </tr>
  );
}
