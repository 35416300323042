/**
 * ViewPage Component
 *
 *   Top-level routing for the main part of the dashboard page
 *
 */
import React, { useState, useEffect } from "react";
import PageViewService from "../../services/PageViewService";
import { useParams, useNavigate, Link } from "@reach/router";

import PageViewsRow from "./PageViewsRow";
import Loader from "../Loader";

export default function PageViews() {
  const navigate = useNavigate();
  const params = useParams();
  const [pageViews, setPageViews] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    PageViewService.list(params.id)
      .then((res) => {
        if (!res) return navigate("/"); // catches out of range errors
        setPageViews(res);
        setLoaded(true);
      })
      .catch((err) => {
        navigate("/");
      });
  }, [params.id, navigate]);

  if (!loaded) {
    return <Loader />;
  }
  return (
    <div>
      <h1 className="mt-4 text-xl font-bold">Page Analytics</h1>
      <div className="min-w-full my-4 overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
        <table className="table min-w-full divide-y divide-gray-200 rounded-md table-auto">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
              >
                Time
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
              >
                Location
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
              >
                Device
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
              >
                Cookie
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {pageViews.map((rowData, index) => (index < 5 ? <PageViewsRow key={rowData.id} rowData={rowData} /> : ""))}
          </tbody>
        </table>
      </div>
      {!pageViews || pageViews.length === 0 ? (
        <div className="text-center">
          No analytics to show.
          <br />
          <Link to="/">
            <button className="inline-flex justify-center px-4 py-2 mt-4 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Go Back
            </button>
          </Link>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
