/**
 * VideoPreviewList Component
 *
 *   Displays a sortable list of videos with add and remove buttons.
 *
 */

// Base
import { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { List, arrayMove } from "react-movable";

// API
import PageService from "../../services/PageService";

// Components
import AddVideoModal from "./AddVideoModal";

export default function VideoPreviewList({ pageId }) {
  // Setup
  const toast = useToasts();

  //State
  const [modalVisible, setModalVisible] = useState(false);
  const [videos, setVideos] = useState([]);
  const [rComp, refreshComponent] = useState(true); // Used to refresh this list

  // Load list of videos when ready
  useEffect(() => {
    if (pageId) PageService.listVideos(pageId).then((res) => setVideos(res));
  }, [pageId, modalVisible, rComp]);

  /**
   * Updates the order of videos both in the state array and in the database.
   *
   * @param {Array} oldIndex
   * @param {Array} newIndex
   */
  async function updateVideoOrder(oldIndex, newIndex) {
    PageService.setVideoOrder(
      pageId,
      arrayMove(videos, oldIndex, newIndex).map((video) => video.id)
    )
      .then((success) => {
        if (success)
          return toast.addToast("New Video Order Saved", {
            appearance: "success",
          });
        return toast.addToast("Failed to save new video order.", { appearance: "error" });
      })
      .catch((err) => toast.addToast("Error while saving new video order.", { appearance: "error" }));
  }

  /**
   * Removes a video from the page.
   *
   * @param {Event} e
   */
  function removeVideo(e) {
    console.log("removing video");
    PageService.removeVideo(pageId, e.currentTarget.dataset.videoid).then((res) => {
      if (res) {
        // Show a toast notification
        toast.addToast("Video Removed", { appearance: "success" });
        refreshComponent(!rComp);
      } else {
        toast.addToast("Error removing video", { appearance: "error" });
      }
    });
  }

  return (
    <div>
      <List
        values={videos}
        onChange={({ oldIndex, newIndex }) => {
          setVideos(arrayMove(videos, oldIndex, newIndex));
          updateVideoOrder(oldIndex, newIndex);
        }}
        renderList={({ children, props, isDragged }) => (
          <ul className="video-list" {...props} style={{ padding: 0, cursor: isDragged ? "grabbing" : undefined }}>
            {children}
          </ul>
        )}
        renderItem={({ value, props, isDragged, isSelected }) => (
          <li
            data-videoid={value.id}
            {...props}
            style={{
              ...props.style,
              listStyleType: "none",
            }}
            className={
              "px-4 py-2 rounded-sm flex flex-row items-center justify-between" +
              (isDragged ? " outline-none ring-2 ring-purple-600 border-transparent bg-white" : "")
            }
          >
            <div className="flex flex-row items-center" data-movable-handle>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="8 0 6 24"
                stroke="currentColor"
                className="w-1 h-4 text-gray-500 mr--4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="8 0 6 24"
                stroke="currentColor"
                className="w-1 h-4 mr-4 text-gray-500"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                />
              </svg>
              <div>{value.title}</div>
            </div>
            <div data-videoid={value.id} onClick={removeVideo}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-red-500"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
          </li>
        )}
      />
      <button id="addVideo" onClick={() => setModalVisible(true)} className="mt-4 text-blue-500">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-8 h-8"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </button>
      <AddVideoModal
        pageId={pageId}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        existingVideos={videos && videos.length > 0 ? videos.map((video) => video.id) : []}
      />
    </div>
  );
}
