/**
 * useAuth
 *
 *   Permits components to check auth status and use auth methods
 *
 */
import React, { useState, useEffect, useContext, createContext } from "react";
import AuthService from "../services/AuthService";

const authContext = createContext();

// Provider component that wraps app and makes auth object
// available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function ConsumeAuth(callback) {
  return <authContext.Consumer>{callback}</authContext.Consumer>;
}

// Hook for child components to get the auth object
// and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [loggedIn, setLoggedIn] = useState(null);
  const [userData, setUserData] = useState({});

  async function login(email, password) {
    return await AuthService.login(email, password).then((res) => {
      if (res.httpStatus === 200) {
        setLoggedIn(true);
        setUserData(res.data);
        return true;
      }
      setLoggedIn(false);
      return false;
    });
  }

  // When the component mounts, get the latest auth object.
  // Todo: determine how to save login state so we don't hit the API constantly.
  useEffect(() => {
    console.log("Logging in..."); // Monitor the number of times we're hitting the API with auth calls
    AuthService.isLoggedIn().then((jsonRes) => {
      if (jsonRes.httpStatus !== 200) {
        return setLoggedIn(false);
      }
      setLoggedIn(true);
      setUserData(jsonRes.data);
    });
  }, []);

  // Return methods and properties accessible to the components
  return {
    loggedIn, // whether the user is logged in (true/false)
    login, // login method taking email and password
    userData, // info about the logged-in user
  };
}
